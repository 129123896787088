import React from 'react';
import Lightbox from 'react-image-lightbox';
import styles from './Events.module.scss';
import 'react-image-lightbox/style.css';

import eventImage from '../../images/event.jpg';
import a from '../../images/events/square/1.jpg';
import b from '../../images/events/square/2.jpg';
import c from '../../images/events/square/3.jpg';
import d from '../../images/events/square/4.jpg';
import e from '../../images/events/square/5.jpg';
import f from '../../images/events/square/6.jpg';
import g from '../../images/events/square/7.jpg';
import h from '../../images/events/square/8.jpg';
import i from '../../images/events/square/9.jpg';
import j from '../../images/events/square/10.jpg';
import k from '../../images/events/square/11.jpg';

 
const images = [
  a,
  b,
  c,
  d,
  e,
  f,
  g,
  h,
  i,
  j,
  k
];

import s from '../../locale/events/s.json';

import {Page, Grid, Text} from '../../components';

interface Props {
  t?: any;
}
interface State {
  photoIndex: number;
  isOpen: boolean;
}

type ComposedProps = Props;

export default class Contact extends React.PureComponent<ComposedProps, State> {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const t = this.props.t || (() => []);
    const { photoIndex, isOpen } = this.state;
    const cssString = `.ReactModal__Content img {transform: none !important;}`;

    return (
      <Page title="Contact">
        <Page.Layout>
          <div className={styles.PageBanner}>
            <div className={styles.PageBannerImageContainer}>
              <img className={styles.PageBannerImage} src={eventImage} />
            </div>
            <div className={styles.PageBannerContent}>
              <Grid>
                <Grid.ScreenWidth>
                  <Text
                    className={styles.PageBannerTitle}
                    tag="h1"
                    color="var(--color-heading-neg)"
                  >
                    {t(s.eventsTitle)}
                  </Text>
                </Grid.ScreenWidth>
              </Grid>
            </div>
          </div>
          <Page.Section>
            <Grid>
              <Grid.ScreenWidth>
                <div>
                  <div className={styles.Gallery}>
                    <style>
                      {cssString}
                    </style>
                    {
                      images.map((image, index)=>
                        <img onClick={() => this.setState({ isOpen: true, photoIndex: index + 1 })} key={index} src={image} />
                      )
                    }
                  </div>
                </div>
              </Grid.ScreenWidth>
            </Grid>
          </Page.Section>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )}
        </Page.Layout>
      </Page>
    );
  }
}
